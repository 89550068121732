var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "log box-shadow padding10" },
    [
      _c("dc-panel", { attrs: { title: "操作日志" } }, [
        _c(
          "div",
          { staticClass: "log" },
          [
            _c(
              "dc-search-panel",
              {
                attrs: { params: _vm.queryPara },
                on: { search: _vm.doSearch, reset: _vm.doSearch },
              },
              [
                _c("dc-input", {
                  attrs: { field: "cName", label: "搜索记录" },
                }),
              ],
              1
            ),
            _c(
              "dc-datatable",
              {
                ref: "list",
                attrs: { url: _vm.urlApi, params: _vm.queryPara },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    type: "index",
                    label: "序号",
                    index: _vm.indexMethod,
                    width: "100",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "coperationContent",
                    label: "操作内容",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", prop: "cip", label: "IP" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "tcreate",
                    label: "操作时间",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "coperator",
                    label: "操作人",
                    width: "180",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }