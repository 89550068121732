var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notificationsSettings scrollbar padding10 box-shadow" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "bcWhite" },
              [
                _c(
                  "el-scrollbar",
                  {},
                  [
                    _c(
                      "el-col",
                      {
                        staticClass:
                          "borderBottom paddingBottom10 marginBottom10",
                      },
                      [
                        _c("span", { staticClass: "fs20 fWB" }, [
                          _vm._v("通知设置"),
                        ]),
                        _c("span", [_vm._v("（当前账号）")]),
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass:
                          "border padding10 borderRadius10 flex orderState",
                      },
                      [
                        _c(
                          "span",
                          [
                            _vm._v("播放语音通知 "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setMsg(
                                      {
                                        type: "bvoiceAnnouncements",
                                        state: _vm.bvoiceAnnouncements,
                                      },
                                      1
                                    )
                                  },
                                },
                              },
                              [_vm._v("设置")]
                            ),
                          ],
                          1
                        ),
                        _c("el-switch", {
                          staticClass: "floatRight",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.bvoiceAnnouncements,
                            callback: function ($$v) {
                              _vm.bvoiceAnnouncements = $$v
                            },
                            expression: "bvoiceAnnouncements",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass:
                          "borderBottom paddingBottom10 marginBottom10 marginTop10",
                      },
                      [
                        _c("span", { staticClass: "fs20 fWB" }, [
                          _vm._v("订单状态变更通知"),
                        ]),
                        _c("span", [_vm._v("（当前账号）")]),
                      ]
                    ),
                    _vm._l(_vm.orderStatusList, function (item) {
                      return _c(
                        "el-col",
                        {
                          key: item.type,
                          staticClass:
                            "border padding10 borderRadius10 marginBottom10 flex orderState h60",
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                          _c(
                            "span",
                            {
                              staticClass: "floatRight",
                              on: {
                                click: function ($event) {
                                  return _vm.setMsg(item, 2)
                                },
                              },
                            },
                            [
                              _c("el-button", { attrs: { type: "text" } }, [
                                _vm._v(
                                  _vm._s(
                                    item.state === 1
                                      ? "提醒一次 "
                                      : item.state === 2
                                      ? "提醒两次 "
                                      : "提醒三次 "
                                  ) + ">"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _c(
                      "el-col",
                      {
                        staticClass:
                          "borderBottom paddingBottom10 marginBottom10 marginTop10",
                      },
                      [
                        _c("span", { staticClass: "fs20 fWB" }, [
                          _vm._v("强提醒通知"),
                        ]),
                      ]
                    ),
                    _vm._l(_vm.remindersList, function (item) {
                      return _c(
                        "el-col",
                        {
                          key: item.type,
                          staticClass:
                            "border padding10 borderRadius10 marginBottom10 flex orderState h60",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("div", [_vm._v(_vm._s(item.name) + "通知")]),
                              item.time
                                ? _c("span", [
                                    _c("span", { staticClass: "fs11" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c("span", { staticClass: "fs15" }, [
                                      _vm._v(_vm._s(item.time)),
                                    ]),
                                    _c("span", { staticClass: "fs11" }, [
                                      _vm._v("分钟后通知提醒"),
                                    ]),
                                  ])
                                : _c(
                                    "span",
                                    { staticClass: "fs11 marginTop5" },
                                    [_vm._v(_vm._s(item.name) + "后通知提醒")]
                                  ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMsg(item, 1)
                                    },
                                  },
                                },
                                [_vm._v("设置")]
                              ),
                            ],
                            1
                          ),
                          _c("el-switch", {
                            staticClass: "floatRight",
                            attrs: { disabled: "" },
                            model: {
                              value: item.state,
                              callback: function ($$v) {
                                _vm.$set(item, "state", $$v)
                              },
                              expression: "item.state",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "设置",
            visible: _vm.msgSetOpen,
            "destroy-on-close": true,
            width: "30%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.msgSetOpen = $event
            },
          },
        },
        [
          _vm.msgSetOpen
            ? _c("msgSet", {
                ref: "msgSet",
                attrs: { "msg-set-data": _vm.msgSetData },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }