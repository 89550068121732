<template>
  <div>
    <el-radio-group v-model="deliveryMode" class="removeCircles">
      <el-radio :label="1" class="wHP border padding10 radioLabel marginBottom10">
        选择一个
        <div class="fs11 marginLeft5 marginTop5">每次配送下单时只选对一个配送</div>
      </el-radio>
      <el-radio :label="2" class="wHP border padding10 radioLabel marginBottom10">
        同时发送
        <div class="fs11 marginLeft5 marginTop5">将选择多个配送进行同时发单</div>
      </el-radio>
      <el-radio :label="4" class="wHP border padding10 radioLabel marginBottom10">
        多选发送
        <div class="fs11 marginLeft5 marginTop5">每隔段时间按配送费由低到高依次发单</div>
        <div class="marginTop5 marginLeft5">
          <el-form ref="form" :model="form" class="paddingTop10">
            <el-form-item label="默认选中">
              <el-select v-model="form.defaultType" :disabled="deliveryMode !== 4" placeholder="请选择活动区域">
                <el-option label="最便宜的第一个配送" :value="1"></el-option>
                <el-option label="最便宜的第二个配送" :value="2"></el-option>
                <el-option label="所有配送" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="间隔时间">
              <el-input-number v-model="form.intervalTime" :disabled="deliveryMode !== 4" :min="1" :max="60" label="分钟" />
            </el-form-item>
          </el-form>
        </div>
      </el-radio>
    </el-radio-group>
    <el-row type="flex" justify="end">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button type="primary" @click="onSubmit">提 交</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "DeliveryOrderSet",
  props: {
    setData: {
      type: [String, Object, Array], // 根据需要指定数据类型
      required: true, // 可选，表示 setData 是必需的
    },
  },
  data() {
    return {
      modeData: "",
      deliveryMode: "",
      form: {
        defaultType: "",
        intervalTime: "",
      },
    };
  },
  mounted() {
    this.modeData = this.setData;
    this.deliveryMode = this.modeData.zdeliveryMode;
    if (this.modeData.zdeliveryDefaultType) {
      this.form.defaultType = this.modeData.zdeliveryDefaultType;
    }
    if (this.modeData.ndeliveryIntervalTime) {
      this.form.intervalTime = this.modeData.ndeliveryIntervalTime;
    }
  },
  methods: {
    //提交
    onSubmit() {
      this.$confirm("确认提交？")
        .then((_) => {
          const setData = JSON.parse(sessionStorage.getItem("deliverySettings"));
          const data = {
            sid: 1,
            zdeliveryMode: this.deliveryMode,
            ndeliveryIntervalTime: this.form.intervalTime,
            zdeliveryDefaultType: this.form.defaultType,
          };
          setData.ndeliveryIntervalTime = this.form.intervalTime;
          setData.zdeliveryDefaultType = this.form.defaultType;
          setData.zdeliveryMode = this.deliveryMode;
          this.topsAjax.post("/api/system/deliverySettings/updateDelSet", data).then((response) => {
            sessionStorage.setItem("deliverySettings", JSON.stringify(setData));
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.cancel();
          });
        })
        .catch((_) => {});
    },
    //取消
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped></style>
