var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "container__top" }, [
      _c(
        "div",
        { staticClass: "indexTabs" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "marginLeft10",
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            _vm._l(_vm.tapArray, function (tab) {
              return _c("el-tab-pane", {
                key: tab.component,
                attrs: { label: tab.menuName, name: tab.component },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "container__body" },
      [
        _vm.tabs === "userSetting"
          ? _c("userSetting", {
              on: { addUser: _vm.addUser, updateUser: _vm.updateUser },
            })
          : _vm._e(),
        _vm.tabs === "menuSetting" ? _c("menuSetting") : _vm._e(),
        _vm.tabs === "roleSetting" ? _c("roleSetting") : _vm._e(),
        _vm.tabs === "addUser"
          ? _c("userAdd", {
              attrs: { "user-id": _vm.userId },
              on: { userSetting: _vm.userSetting },
            })
          : _vm._e(),
        _vm.tabs === "updateUser"
          ? _c("userEdit", {
              attrs: { "user-id": _vm.userId },
              on: { userSetting: _vm.userSetting },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }