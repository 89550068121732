<template>
  <!-- 自动发单设置-->
  <div>
    <el-form label-position="left" :model="form">
      <el-form-item label="时长">
        <el-input-number v-model="form.time" :min="1" :max="60" label="描述文字" />
      </el-form-item>
      <el-form-item v-if="form.tipAmount" label="金额">
        <el-input-number v-model="form.tipAmount" :min="1" :max="60" label="描述文字" />
      </el-form-item>
      <el-form-item label="状态" class="el-form-item-top">
        <el-switch v-model="form.state"></el-switch>
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button type="primary" @click="onSubmit">提 交</el-button>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "DeliverySet",
  props: {
    setData: {
      type: [String, Object, Array], // 根据需要指定数据类型
      required: true, // 可选，表示 setData 是必需的
    },
  },
  data() {
    return {
      form: {
        tipAmount: "",
        state: "",
        time: "",
      },
      setType: "",
    };
  },
  mounted() {
    this.setType = this.setData.type;
    this.form.state = this.setData.state;
    this.form.time = this.setData.time;
    if (this.setData.tipAmount) {
      this.form.tipAmount = this.setData.tipAmount;
    }
  },
  methods: {
    //提交
    onSubmit() {
      this.$confirm("确认提交？")
        .then((_) => {
          const setData = JSON.parse(sessionStorage.getItem("deliverySettings"));
          const data = {
            sid: 1,
          };
          if (this.setType === 1) {
            data.btimelyAutoSend = this.form.state === true ? 1 : 0;
            data.ntimelyAutoSendTime = this.form.time;
            setData.btimelyAutoSend = this.form.state;
            setData.ntimelyAutoSendTime = this.form.time;
          } else if (this.setType === 2) {
            data.breservationAutoDelivery = this.form.state === true ? 1 : 0;
            data.nreservationAutoDeliveryTime = this.form.time;
            setData.breservationAutoDelivery = this.form.state;
            setData.nreservationAutoDeliveryTime = this.form.time;
          } else {
            data.bautoTip = this.form.state === true ? 1 : 0;
            data.nautoTipTime = this.form.time;
            data.ntipAmount = this.form.tipAmount;
            setData.bautoTip = this.form.state;
            setData.nautoTipTime = this.form.time;
            setData.ntipAmount = this.form.tipAmount;
          }
          this.topsAjax.post("/api/system/deliverySettings/updateDelSet", data).then((response) => {
            sessionStorage.setItem("deliverySettings", JSON.stringify(setData));
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.cancel();
          });
        })
        .catch((_) => {});
    },
    //取消
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>
