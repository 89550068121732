<template>
  <!-- 常用备注修改-->
  <div>
    <el-form label-position="left" :model="form">
      <el-form-item v-if="setType !== 3" :label="title">
        <el-input v-model="form.message" type="textarea"></el-input>
      </el-form-item>
      <el-form-item v-if="setType !== 3 && setType !== 4" label="状态" class="el-form-item-top">
        <el-switch v-model="form.state" />
      </el-form-item>
      <el-form-item v-if="setType === 3" label="常用备注">
        <el-input v-model="form.desc1" placeholder="请输入备注" style="width: 50%"></el-input>
        <el-button type="primary" @click="handleInputConfirm">添加</el-button>
      </el-form-item>
    </el-form>
    <el-tag v-for="tag in notesArray" :key="tag" class="wHP" closable :disable-transitions="false" @close="handleClose(tag)">
      <span v-if="setType === 3">{{ tag }}</span>
    </el-tag>
    <el-row type="flex" class="marginTop10" justify="end">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button type="primary" @click="onSubmit">提 交</el-button>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "RemarkSet",
  props: {
    setData: {
      type: [String, Object, Array], // 根据需要指定数据类型
      required: true, // 可选，表示 setData 是必需的
    },
  },
  data() {
    return {
      desc1: "",
      title: "",
      form: {
        message: "",
        state: "",
      },
      setType: "",
      notesArray: [],
    };
  },
  mounted() {
    this.setType = this.setData.type;
    if (this.setData.isState) {
      this.form.state = this.setData.state;
    }
    if (this.setData.title) {
      this.title = this.setData.title;
    }
    if (this.setData.message) {
      this.form.message = this.setData.message;
    }
    if (this.setData.notesArray) {
      this.notesArray = JSON.parse(this.setData.notesArray);
    }
  },
  methods: {
    //提交
    onSubmit() {
      this.$confirm("确认提交？")
        .then((_) => {
          const setData = JSON.parse(sessionStorage.getItem("deliverySettings"));
          const data = {
            sid: 1,
          };
          if (this.setType === 1) {
            data.bautoReplyReminder = this.form.state === true ? 1 : 0;
            data.creplyReminderMessage = this.form.message;
            setData.bautoReplyReminder = this.form.state;
            setData.creplyReminderMessage = this.form.message;
          } else if (this.setType === 2) {
            data.bautoSendReminder = this.form.state === true ? 1 : 0;
            data.csendReminderMessage = this.form.message;
            setData.bautoSendReminder = this.form.state;
            setData.csendReminderMessage = this.form.message;
          } else if (this.setType === 3) {
            const jsonStr = JSON.stringify(this.notesArray);
            data.acommonNotes = jsonStr;
            setData.acommonNotes = jsonStr;
          } else {
            data.cdefaultNotes = this.form.message;
            setData.cdefaultNotes = this.form.message;
          }
          this.topsAjax.post("/api/system/deliverySettings/updateDelSet", data).then((response) => {
            sessionStorage.setItem("deliverySettings", JSON.stringify(setData));
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.cancel();
          });
        })
        .catch((_) => {});
    },
    /**
     * 添加常用备注
     */
    handleInputConfirm() {
      let inputValue = this.form.desc1;
      if (this.notesArray.length < 6) {
        if (inputValue) {
          this.notesArray.push(inputValue);
        }
      } else {
        this.$alert("常用备注不能超过6个", {
          confirmButtonText: "确定",
        });
      }
      this.form.desc1 = "";
    },
    /**
     * 删除指定备注
     * @param tag
     */
    handleClose(tag) {
      this.notesArray.splice(this.notesArray.indexOf(tag), 1);
    },
    //取消
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 0;
  margin-top: 10px;
}
</style>
