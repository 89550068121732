var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          staticClass: "removeCircles",
          model: {
            value: _vm.deliveryMode,
            callback: function ($$v) {
              _vm.deliveryMode = $$v
            },
            expression: "deliveryMode",
          },
        },
        [
          _c(
            "el-radio",
            {
              staticClass: "wHP border padding10 radioLabel marginBottom10",
              attrs: { label: 1 },
            },
            [
              _vm._v(" 选择一个 "),
              _c("div", { staticClass: "fs11 marginLeft5 marginTop5" }, [
                _vm._v("每次配送下单时只选对一个配送"),
              ]),
            ]
          ),
          _c(
            "el-radio",
            {
              staticClass: "wHP border padding10 radioLabel marginBottom10",
              attrs: { label: 2 },
            },
            [
              _vm._v(" 同时发送 "),
              _c("div", { staticClass: "fs11 marginLeft5 marginTop5" }, [
                _vm._v("将选择多个配送进行同时发单"),
              ]),
            ]
          ),
          _c(
            "el-radio",
            {
              staticClass: "wHP border padding10 radioLabel marginBottom10",
              attrs: { label: 4 },
            },
            [
              _vm._v(" 多选发送 "),
              _c("div", { staticClass: "fs11 marginLeft5 marginTop5" }, [
                _vm._v("每隔段时间按配送费由低到高依次发单"),
              ]),
              _c(
                "div",
                { staticClass: "marginTop5 marginLeft5" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "paddingTop10",
                      attrs: { model: _vm.form },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "默认选中" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.deliveryMode !== 4,
                                placeholder: "请选择活动区域",
                              },
                              model: {
                                value: _vm.form.defaultType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "defaultType", $$v)
                                },
                                expression: "form.defaultType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "最便宜的第一个配送",
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "最便宜的第二个配送",
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                attrs: { label: "所有配送", value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "间隔时间" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              disabled: _vm.deliveryMode !== 4,
                              min: 1,
                              max: 60,
                              label: "分钟",
                            },
                            model: {
                              value: _vm.form.intervalTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "intervalTime", $$v)
                              },
                              expression: "form.intervalTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }