var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-position": "left", model: _vm.form } },
        [
          _vm.setType !== 3
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.title } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "message", $$v)
                      },
                      expression: "form.message",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.setType !== 3 && _vm.setType !== 4
            ? _c(
                "el-form-item",
                { staticClass: "el-form-item-top", attrs: { label: "状态" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "state", $$v)
                      },
                      expression: "form.state",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.setType === 3
            ? _c(
                "el-form-item",
                { attrs: { label: "常用备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.desc1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc1", $$v)
                      },
                      expression: "form.desc1",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleInputConfirm },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._l(_vm.notesArray, function (tag) {
        return _c(
          "el-tag",
          {
            key: tag,
            staticClass: "wHP",
            attrs: { closable: "", "disable-transitions": false },
            on: {
              close: function ($event) {
                return _vm.handleClose(tag)
              },
            },
          },
          [_vm.setType === 3 ? _c("span", [_vm._v(_vm._s(tag))]) : _vm._e()]
        )
      }),
      _c(
        "el-row",
        { staticClass: "marginTop10", attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }