var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu box-shadow padding10" },
    [
      _c("dc-panel", { attrs: { title: "角色管理" } }, [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c(
              "div",
              { staticClass: "flex marginBottom10" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { clearable: "", placeholder: "请输入角色名称" },
                    model: {
                      value: _vm.queryPara.menuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryPara, "menuName", $$v)
                      },
                      expression: "queryPara.menuName",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.doSearch },
                      slot: "append",
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marginLeftAuto",
                    attrs: { type: "primary" },
                    on: { click: _vm.addRole },
                  },
                  [_vm._v("新增角色")]
                ),
              ],
              1
            ),
            _c(
              "dc-datatable",
              {
                ref: "list",
                attrs: {
                  url: _vm.urlApi,
                  params: _vm.queryPara,
                  "tooltip-effect": "dark",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    type: "index",
                    label: "序号",
                    index: _vm.indexMethod,
                    width: "63",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "roleName",
                    label: "角色名称",
                    width: "120",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "marginLeft10" }, [
                            _vm._v(" " + _vm._s(scope.row.roleName) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "status",
                    label: "角色标识",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(" " + _vm._s(scope.row.roleKey) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "menusStr",
                    label: "所属菜单",
                    width: "320",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(" " + _vm._s(scope.row.menusStr) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "remark",
                    label: "角色描述",
                    width: "250",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(" " + _vm._s(scope.row.remark) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "status",
                    label: "账号状态",
                    width: "80",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.status === "0" ? "启用" : "禁用"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "address",
                    label: "操作",
                    width: "150",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "green",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.authority(scope.row)
                                },
                              },
                            },
                            [_vm._v("权限")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateRole(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "red",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteRole(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.addRoleVisible,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addRoleVisible = $event
            },
            close: _vm.dialogClose,
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.formData, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "roleName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入角色名称",
                      disabled: _vm.roleNameDisabled,
                    },
                    model: {
                      value: _vm.formData.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "roleName", $$v)
                      },
                      expression: "formData.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色标识", prop: "roleKey" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入角色标识" },
                    model: {
                      value: _vm.formData.roleKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "roleKey", $$v)
                      },
                      expression: "formData.roleKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色描述", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "marginLeft10",
                    attrs: { placeholder: "请输入角色描述" },
                    model: {
                      value: _vm.formData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "remark", $$v)
                      },
                      expression: "formData.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "启用状态", prop: "status" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.formData.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addRoleVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "权限配置",
            visible: _vm.setAuthorityVisible,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.setAuthorityVisible = $event
            },
            open: _vm.treeSelect,
          },
        },
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.menuOptions,
              "show-checkbox": "",
              "node-key": "id",
              "default-expand-all": "",
              "default-checked-keys": _vm.checkedKeys,
              props: _vm.defaultProps,
            },
            on: { "check-change": _vm.checkChange },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setAuthorityVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitAuthority },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }