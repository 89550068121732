<template>
  <!-- 通知设置-->
  <div class="notificationsSettings scrollbar padding10 box-shadow">
    <el-row>
      <el-col :span="24">
        <div class="bcWhite">
          <el-scrollbar class="">
            <el-col class="borderBottom paddingBottom10 marginBottom10">
              <span class="fs20 fWB">通知设置</span>
              <span>（当前账号）</span>
            </el-col>
            <el-col class="border padding10 borderRadius10 flex orderState">
              <span
                >播放语音通知
                <el-button type="text" size="mini" @click="setMsg({ type: 'bvoiceAnnouncements', state: bvoiceAnnouncements }, 1)">设置</el-button>
              </span>
              <el-switch v-model="bvoiceAnnouncements" class="floatRight" disabled />
            </el-col>
            <el-col class="borderBottom paddingBottom10 marginBottom10 marginTop10">
              <span class="fs20 fWB">订单状态变更通知</span>
              <span>（当前账号）</span>
            </el-col>
            <el-col v-for="item in orderStatusList" :key="item.type" class="border padding10 borderRadius10 marginBottom10 flex orderState h60">
              <span>{{ item.name }}</span>
              <span class="floatRight" @click="setMsg(item, 2)">
                <el-button type="text">{{ item.state === 1 ? "提醒一次 " : item.state === 2 ? "提醒两次 " : "提醒三次 " }}></el-button>
              </span>
            </el-col>
            <el-col class="borderBottom paddingBottom10 marginBottom10 marginTop10">
              <span class="fs20 fWB">强提醒通知</span>
            </el-col>
            <el-col v-for="item in remindersList" :key="item.type" class="border padding10 borderRadius10 marginBottom10 flex orderState h60">
              <div>
                <div>{{ item.name }}通知</div>
                <span v-if="item.time">
                  <span class="fs11">{{ item.name }}</span>
                  <span class="fs15">{{ item.time }}</span>
                  <span class="fs11">分钟后通知提醒</span>
                </span>
                <span v-else class="fs11 marginTop5">{{ item.name }}后通知提醒</span>
                <el-button type="text" size="mini" @click="setMsg(item, 1)">设置</el-button>
              </div>
              <el-switch v-model="item.state" class="floatRight" disabled />
            </el-col>
          </el-scrollbar>
        </div>
      </el-col>
    </el-row>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="设置" :visible.sync="msgSetOpen" :destroy-on-close="true" width="30%" :before-close="handleClose" append-to-body>
      <msgSet v-if="msgSetOpen" ref="msgSet" :msg-set-data="msgSetData" @handleClose="handleClose" />
    </el-dialog>
  </div>
</template>
<script>
import msgSet from "./msgSet.vue";

export default {
  name: "NotificationsSettings",
  components: {
    msgSet,
  },
  data() {
    return {
      bvoiceAnnouncements: "",
      remindersList: [],
      orderStatusList: [],
      msgSetOpen: false,
      msgSetData: "",
    };
  },
  mounted() {
    this.dataRender();
  },
  methods: {
    //消息设置
    setMsg(item, set) {
      item.set = set;
      this.msgSetData = JSON.parse(JSON.stringify(item));
      this.msgSetOpen = true;
    },
    //关闭弹窗
    handleClose() {
      this.dataRender();
      this.msgSetData = "";
      this.msgSetOpen = false;
    },
    /**
     * 数据渲染
     * @param done
     */
    dataRender() {
      const noticeData = JSON.parse(sessionStorage.getItem("notificationsSettings"));
      this.bvoiceAnnouncements = noticeData.bvoiceAnnouncements;
      this.remindersList = [
        { type: "breservationListBefore", name: "预约单发单前", state: noticeData.breservationListBefore, time: noticeData.nbeforeReservationTime, timeType: "nbeforeReservationTime" },
        { type: "borderModify", name: "订单修改", state: noticeData.borderModify },
        { type: "bdeliveryTimeout", name: "配送超时未接单", state: noticeData.bdeliveryTimeout, time: noticeData.ndeliveryTimeoutTime, timeType: "ndeliveryTimeoutTime" },
        { type: "bdeliveryAbnormal", name: "配送异常", state: noticeData.bdeliveryAbnormal },
        { type: "bdeliveryCancel", name: "配送取消失败", state: noticeData.bdeliveryCancel },
        { type: "bautoDeliveryBalance", name: "自动发单配送余额不足提醒", state: noticeData.bautoDeliveryBalance },
        { type: "btimeoutCallRider", name: "超时未到店呼叫骑手提醒", state: noticeData.btimeoutCallRider, time: noticeData.ntimeoutCallRiderTime, timeType: "ntimeoutCallRiderTime" },
      ];
      this.orderStatusList = [
        { type: "znewOrder", name: "新订单", state: noticeData.znewOrder },
        { type: "zconfirmOrder", name: "确认订单", state: noticeData.zconfirmOrder },
        { type: "zuserReminder", name: "用户催单", state: noticeData.zuserReminder },
        { type: "zcancelOrder", name: "订单取消", state: noticeData.zcancelOrder },
        { type: "zfinishOrder", name: "订单完成", state: noticeData.zfinishOrder },
        { type: "zdeliveryException", name: "配送异常", state: noticeData.zdeliveryException },
        { type: "zdeletOrder", name: "订单删除", state: noticeData.zdeletOrder },
        { type: "zrevokeOrder", name: "订单撤回", state: noticeData.zrevokeOrder },
        { type: "zdeliveryPlace", name: "配送下单", state: noticeData.zdeliveryPlace },
        { type: "zdeliveryReceiving", name: "配送接单", state: noticeData.zdeliveryReceiving },
        { type: "zdeliveryStart", name: "开始配送", state: noticeData.zdeliveryStart },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.notificationsSettings {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;
  &__body {
    overflow: auto;
    height: 100%;
  }
}
</style>
<style lang="scss">
.orderState {
  background-color: rgb(247, 248, 250);
  justify-content: space-between;
  align-items: center;
}
</style>
