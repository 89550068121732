<template>
  <!-- 配送设置-->
  <div class="deliverySettings box-shadow">
    <div class="deliverySettings__body padding10">
      <div class="fs20 fWB borderBottom paddingBottom10">手动发单</div>
      <div class="marginTop10 orderState h60">
        <div>
          <div>配送下单选择模式</div>
          <div class="fs11">选择发单时选择一个或多个配送</div>
        </div>
        <!--        <div @click="openDialog(1)">设置 ></div>-->
        <el-button type="text" @click="openDialog(1)">设置 ></el-button>
      </div>
      <div class="fs20 fWB borderBottom paddingBottom10 marginTop10 marginBottom10">自动发单</div>
      <div class="marginBottom10 orderState h70">
        <div>
          <div>及时单自动发单</div>
          <div class="fs11">根据设置的时间来单后自动呼叫配送，如有多个将发最便宜的一个配送</div>
          <div class="fs11">
            <span class="fs11">顾客下单后</span>
            <span>{{ deliveryData.ntimelyAutoSendTime }}</span>
            <span class="fs11">分钟</span>
            <el-button type="text" size="mini" @click="openDialog(2, { type: 1, state: deliveryData.btimelyAutoSend, time: deliveryData.ntimelyAutoSendTime })">设置</el-button>
          </div>
        </div>
        <el-switch v-model="deliveryData.btimelyAutoSend" disabled class="floatRight" />
      </div>
      <div class="marginBottom10 orderState h70">
        <div>
          <div>预订单自动配送</div>
          <div class="fs11">设置的时长为预约单期望送达前多长时间自动呼叫配送，如不设置将按系统建议时间</div>
          <div class="fs11">
            <span class="fs11">系统建议发货时间</span>
            <span>{{ deliveryData.nreservationAutoDeliveryTime }}</span>
            <span class="fs11">分钟</span>
            <el-button type="text" size="mini" @click="openDialog(2, { type: 2, state: deliveryData.breservationAutoDelivery, time: deliveryData.nreservationAutoDeliveryTime })">设置</el-button>
          </div>
        </div>
        <el-switch v-model="deliveryData.breservationAutoDelivery" disabled class="floatRight" />
      </div>
      <div class="marginBottom10 orderState h60">
        <div>
          <div>自动加小费</div>
          <div class="fs11">
            <span class="fs11">配送下单</span>
            <span>{{ deliveryData.nautoTipTime }}</span>
            <span class="fs11">分钟后，如未接单加小费{{ deliveryData.ntipAmount }}元</span>
            <el-button type="text" size="mini" @click="openDialog(2, { type: 3, state: deliveryData.bautoTip, time: deliveryData.nautoTipTime, tipAmount: deliveryData.ntipAmount })">设置</el-button>
          </div>
        </div>
        <el-switch v-model="deliveryData.bautoTip" disabled class="floatRight" />
      </div>
      <div class="fs20 fWB borderBottom paddingBottom10 marginTop10 marginBottom10">催单设置</div>
      <div class="marginBottom10 orderState h60">
        <div>
          <div>自动回复催单</div>
          <div class="fs11 marginTop5">
            有催单时将根据当前订单状态自动回复顾客
            <el-button type="text" size="mini" @click="openDialog(3, { type: 1, state: deliveryData.bautoReplyReminder, message: deliveryData.creplyReminderMessage, title: '回复内容', isState: true })">设置</el-button>
          </div>
        </div>
        <el-switch v-model="deliveryData.bautoReplyReminder" disabled class="floatRight" />
      </div>
      <div class="marginBottom10 orderState h60">
        <div>
          <div>自动发送催单短信</div>
          <div class="fs11 marginTop5">
            顾客催单后系统自动发送一条催单给骑手
            <el-button type="text" size="mini" @click="openDialog(3, { type: 2, state: deliveryData.bautoSendReminder, message: deliveryData.csendReminderMessage, title: '催单内容', isState: true })">设置</el-button>
          </div>
        </div>
        <el-switch v-model="deliveryData.bautoSendReminder" disabled class="floatRight" />
      </div>
      <div class="fs20 fWB borderBottom paddingBottom10 marginTop10 marginBottom10">备注设置</div>
      <div class="marginBottom10 orderState h60">
        <div>
          <div>设置常用备注</div>
          <div class="fs11 marginTop5">发单时默认显示经常输入的六个备注</div>
        </div>
        <el-button type="text" size="mini" @click="openDialog(3, { type: 3, notesArray: deliveryData.acommonNotes })">设置 ></el-button>
      </div>
      <div class="marginBottom10 orderState h60">
        <div>
          <div>设置默认备注</div>
          <div class="fs11 marginTop5">设置后每次发单都会默认显示备注</div>
        </div>
        <el-button type="text" size="mini" @click="openDialog(3, { type: 4, message: deliveryData.cdefaultNotes, title: '默认备注' })">设置 ></el-button>
      </div>
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="配送下单模式" :visible.sync="deliverySetDialogVisible" width="30%" :before-close="handleClose" append-to-body>
      <deliveryOrderSet v-if="deliverySetDialogVisible" ref="deliveryOrderSet" :set-data="deliveryData" @handleClose="handleClose" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="设置" :visible.sync="deliveryOpen" width="30%" :before-close="handleClose" append-to-body>
      <deliverySet v-if="deliveryOpen" ref="deliverySet" :set-data="setData" @handleClose="handleClose" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="备注设置" :visible.sync="remarkSetOpen" width="30%" :before-close="handleClose" append-to-body>
      <remarkSet v-if="remarkSetOpen" ref="remarkSet" :set-data="setData" @handleClose="handleClose" />
    </el-dialog>
  </div>
</template>
<script>
import deliverySet from "./deliverySet";
import remarkSet from "./remarkSet";
import DeliveryOrderSet from "./deliveryOrderSet.vue";

export default {
  name: "DeliverySetting",
  components: {
    deliverySet,
    remarkSet,
    DeliveryOrderSet,
  },
  data() {
    return {
      deliveryData: {},
      deliveryOpen: false,
      remarkSetOpen: false,
      deliverySetDialogVisible: false,
      setData: "",
    };
  },
  mounted() {
    this.dataRender();
  },
  methods: {
    /**
     * 打开设置弹窗
     */
    openDialog(setType, data) {
      if (setType === 1) {
        this.deliverySetDialogVisible = true;
      } else if (setType === 2) {
        this.setData = data;
        this.deliveryOpen = true;
      } else {
        this.setData = data;
        this.remarkSetOpen = true;
      }
    },
    //关闭弹窗
    handleClose() {
      this.dataRender();
      this.setData = "";
      this.deliveryOpen = false;
      this.remarkSetOpen = false;
      this.deliverySetDialogVisible = false;
    },
    /**
     * 数据渲染
     * @param done
     */
    dataRender() {
      this.deliveryData = JSON.parse(sessionStorage.getItem("deliverySettings"));
    },
  },
};
</script>
<style lang="scss" scoped>
.deliverySettings {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;
  &__body {
    overflow: auto;
    height: 100%;
  }
}
</style>
<style lang="scss">
.el-button--text {
  padding: 0;
}
.orderState {
  border-radius: 10px;
  border: 1px solid rgb(222, 222, 222);
  padding: 10px;
  display: flex;
  background-color: rgb(247, 248, 250);
  justify-content: space-between;
  align-items: center;
}
</style>
