<template>
  <!--   日志列表-->
  <div class="log box-shadow padding10">
    <dc-panel title="操作日志">
      <div class="log">
        <dc-search-panel :params="queryPara" @search="doSearch" @reset="doSearch">
          <dc-input field="cName" label="搜索记录" />
        </dc-search-panel>
        <dc-datatable ref="list" :url="urlApi" :params="queryPara">
          <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="100" />
          <el-table-column align="center" prop="coperationContent" label="操作内容" />
          <el-table-column align="center" prop="cip" label="IP" />
          <el-table-column align="center" prop="tcreate" label="操作时间" width="180" />
          <el-table-column align="center" prop="coperator" label="操作人" width="180" />
        </dc-datatable>
      </div>
    </dc-panel>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "Log",
  data() {
    return {
      urlApi: `${this.baseUrls.log}/queryPageList`,
      queryPara: {},
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 15,
      },
    };
  },
  mounted() {},
  methods: {
    doSearch() {
      this.$refs.list.load();
    },
    doReset() {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    handleClick(tab, event) {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      $table.resetHeight();
      this.doSearch();
    },
    getMonth(value) {
      if (value === 1) {
        this.queryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      } else if (value === 2) {
        this.queryPara.startTime = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      } else if (value === 3) {
        this.queryPara.startTime = moment().subtract(3, "months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().format("YYYY-MM-DD");
      }
      this.doSearch();
    },
    indexMethod(index) {
      let page = this.$refs.list.page;
      return index + 1 + (page.currentPage - 1) * page.pageSize;
    },
  },
};
</script>
<style lang="scss" scoped>
.log {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;
  &__body {
    overflow: auto;
    height: 100%;
  }
}

.frame {
  border: 1px solid lightgray;
}
.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}
</style>
