<template>
  <div class="container">
    <div class="container__left bcWhite margin10 box-shadow">
      <div class="leftTaps">
        <el-tabs v-model="tabs" class="tops" tab-position="left">
          <el-tab-pane v-for="tab in tapArray" :key="tab.component" :label="tab.menuName" :name="tab.component" />
        </el-tabs>
      </div>
    </div>
    <div class="container__right margin10 borderRadius5">
      <merchant v-if="tabs === 'merchantSetting'" />
      <userIndex v-if="tabs === 'userIndex'" />
      <notifications v-if="tabs === 'notificationsSettings'" />
      <delivery v-if="tabs === 'deliverySettings'" />
      <log v-if="tabs === 'log'" />
    </div>
  </div>
</template>
<script>
import log from "./log";
import store from "../../store";
import merchant from "./merchant";
import delivery from "./deliverySetting";
import userIndex from "./userIndex";
import notifications from "./notificationsSettings.vue";

import Log from "./log.vue";
export default {
  name: "Index",
  components: {
    userIndex,
    merchant,
    notifications,
    delivery,
    log,
  },
  data() {
    return {
      tabs: "",
      tapArray: "",
    };
  },
  created() {
    const nav = JSON.parse(JSON.stringify(store.getters.navs));
    const result = nav.find((item) => item.component === "settingsIndex");
    this.tapArray = result.children;
    this.tabs = this.tapArray[0].component;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.container {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex: 1;
  //background-color: #e5e5e5;
  background-color: #f6f6f6;
  &__left {
    margin: 15px;
    min-width: 15%;
    display: block;
  }
  &__right {
    flex: 1;
    display: block;
  }
}
</style>

<style lang="scss"></style>
