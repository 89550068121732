<template>
  <!-- 新增修改商户-->
  <div class="merchantInfo">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="90px">
      <div class="wHP flex">
        <div class="merchantInfo__left">
          <el-form-item label="商户名称" prop="cname">
            <el-input v-model="ruleForm.cname" />
          </el-form-item>
          <el-form-item label="上级组织" prop="rparentId">
            <el-select v-model="ruleForm.rparentId" filterable placeholder="请选择">
              <el-option v-for="item in parentOptions" :key="item.sid" :label="item.cname" :value="item.sid" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="ccontacts">
            <el-input v-model="ruleForm.ccontacts" />
          </el-form-item>
          <el-form-item label="手机号码" prop="cphone">
            <el-input v-model="ruleForm.cphone" />
          </el-form-item>
          <el-form-item label="开户银行" prop="cbank">
            <el-input v-model="ruleForm.cbank" />
          </el-form-item>
          <el-form-item label="账号户名" prop="cbankAccountName">
            <el-input v-model="ruleForm.cbankAccountName" />
          </el-form-item>
          <el-form-item label="银行账号" prop="cbankAccountNum">
            <el-input v-model="ruleForm.cbankAccountNum" />
          </el-form-item>
          <el-form-item label="排序号" prop="nindex">
            <el-input v-model="ruleForm.nindex" />
          </el-form-item>
        </div>
        <div class="merchantInfo__right">
          <el-form-item label="编号" prop="ccode">
            <el-input v-model="ruleForm.ccode" />
          </el-form-item>
          <div class="h350 wHP bcGrey"></div>
          <el-form-item label="是否启用">
            <el-switch v-model="ruleForm.bstate" />
          </el-form-item>
        </div>
      </div>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="onSubmit('ruleForm')">提 交</el-button>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { rules } from "@/views/settings/merchantInfo";

export default {
  name: "MerchantInfo",
  props: {
    updateId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
    parentId: {
      type: [String, Object, Array],
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      rules: rules,
      ruleForm: {
        sid: "",
        //上级id
        rparentId: "",
        //名称
        cname: "",
        //联系人
        ccontacts: "",
        //联系电话
        cphone: "",
        //开户行
        cbank: "",
        //账号户名
        cbankAccountName: "",
        //银行账号
        cbankAccountNum: "",
        //编号
        ccode: "",
        //排序号
        nindex: "",
        //是否启用
        bstate: true,
      },
      // 列表数据
      parentOptions: [],
    };
  },
  created() {
    this.selectParent();
    if (this.parentId) {
      this.ruleForm.rparentId = this.parentId;
    } else if (this.updateId) {
      this.topsAjax.get(`${this.baseUrls.merchant}/selectById`, { sid: this.updateId }).then((response) => {
        this.ruleForm.sid = response.sid;
        this.ruleForm.cname = response.cname;
        this.ruleForm.ccode = response.ccode;
        this.ruleForm.ccontacts = response.ccontacts;
        this.ruleForm.cphone = response.cphone;
        this.ruleForm.cbank = response.cbank;
        this.ruleForm.cbankAccountName = response.cbankAccountName;
        this.ruleForm.cbankAccountNum = response.cbankAccountNum;
        this.ruleForm.nindex = response.nindex;
        this.ruleForm.rparentId = response.rparentId;
        this.ruleForm.bstate = response.bstate !== 1;
      });
    }
  },
  methods: {
    selectParent() {
      this.topsAjax.get(`${this.baseUrls.merchant}/selectParent`).then((response) => {
        response.unshift({ sid: "0", cname: "无" });
        this.parentOptions = response;
      });
    },
    //提交
    onSubmit() {
      const data = JSON.parse(JSON.stringify(this.ruleForm));
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确认提交？")
            .then((_) => {
              data.bstate = data.bstate ? 0 : 1;
              this.topsAjax.post(`${this.baseUrls.merchant}/submit`, data).then((response) => {
                this.$message({
                  message: this.ruleForm.sid ? "修改成功" : "新增成功",
                  type: "success",
                });
                this.cancel();
              });
            })
            .catch((_) => {});
        }
      });
    },
    //取消
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style lang="scss">
.merchantInfo {
  width: 100%;
  &__left {
    width: 50%;
  }
  &__right {
    width: 50%;
  }
}
</style>
