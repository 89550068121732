<template>
  <!-- 消息设置修改-->
  <div>
    <el-form label-position="left" :model="form">
      <el-form-item v-if="form.set === 1 && form.time" label="时长">
        <el-input-number v-model="form.time" :min="1" :max="60" label="描述文字" />
      </el-form-item>
      <el-form-item v-if="form.set === 1" label="状态" class="el-form-item-top">
        <el-switch v-model="form.state"></el-switch>
      </el-form-item>
      <el-form-item v-if="form.set === 2" label="提醒次数">
        <el-select v-model="form.state" placeholder="请选择">
          <el-option label="提醒一次" :value="1" />
          <el-option label="提醒两次" :value="2" />
          <el-option label="提醒三次" :value="3" />
        </el-select>
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button size="medium" @click="cancel">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 定</el-button>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "MsgSet",
  props: {
    msgSetData: {
      type: [String, Object, Array], // 根据需要指定数据类型
      required: true, // 可选，表示 msgSetData 是必需的
    },
  },
  data() {
    return {
      form: {
        state: "",
        time: "",
        timeType: "",
        num: "",
        set: "",
      },
    };
  },
  mounted() {
    this.form = this.msgSetData;
  },
  methods: {
    //提交
    onSubmit() {
      this.$confirm("确认提交？")
        .then((_) => {
          const noticeData = JSON.parse(sessionStorage.getItem("notificationsSettings"));
          const data = { sid: noticeData.sid };
          if (this.form.set === 1) {
            if (this.form.time) {
              data[this.form.type] = this.form.state === true ? 1 : 0;
              data[this.form.timeType] = this.form.time;
              noticeData[this.form.type] = this.form.state;
              noticeData[this.form.timeType] = this.form.time;
            } else {
              data[this.form.type] = this.form.state === true ? 1 : 0;
              noticeData[this.form.type] = this.form.state;
            }
          } else {
            data[this.form.type] = this.form.state;
            noticeData[this.form.type] = this.form.state;
          }
          this.topsAjax.post("/api/system/notificationsSettings/updateById", data).then((response) => {
            sessionStorage.setItem("notificationsSettings", JSON.stringify(noticeData));
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.cancel();
          });
        })
        .catch((_) => {});
    },
    //取消
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>
