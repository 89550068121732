<template>
  <div class="container">
    <div class="container__top">
      <div class="indexTabs">
        <el-tabs v-model="tabs" class="marginLeft10">
          <el-tab-pane v-for="tab in tapArray" :key="tab.component" :label="tab.menuName" :name="tab.component" />
        </el-tabs>
      </div>
    </div>
    <div class="container__body">
      <userSetting v-if="tabs === 'userSetting'" @addUser="addUser" @updateUser="updateUser" />
      <menuSetting v-if="tabs === 'menuSetting'" />
      <roleSetting v-if="tabs === 'roleSetting'" />
      <userAdd v-if="tabs === 'addUser'" :user-id="userId" @userSetting="userSetting" />
      <userEdit v-if="tabs === 'updateUser'" :user-id="userId" @userSetting="userSetting" />
    </div>
  </div>
</template>

<script>
import store from "../../store";
import userSetting from "./userSetting.vue";
import menuSetting from "./menuSetting.vue";
import roleSetting from "./roleSetting.vue";
import userAdd from "@/views/settings/userAdd.vue";
import userEdit from "@/views/settings/userEdit.vue";

export default {
  name: "Order",
  components: {
    menuSetting,
    userSetting,
    roleSetting,
    userAdd,
    userEdit,
  },
  data() {
    return {
      userId: "",
      tabs: "",
      tapArray: "",
      search: "",
      dialogVisible: false,
    };
  },
  created() {
    const nav = JSON.parse(JSON.stringify(store.getters.navs));
    const result = nav.find((item) => item.component === "settingsIndex");
    const userIndex = result.children.find((item) => item.component === "userIndex");
    this.tapArray = userIndex.children;
    this.tabs = this.tapArray[0].component;
  },
  methods: {
    /**
     * 添加账号
     */
    addUser() {
      this.userId = "";
      this.tabs = "addUser";
    },
    /**
     * 添加账号
     */
    updateUser(userId) {
      this.userId = userId;
      this.tabs = "updateUser";
    },
    /**
     * 账号管理
     */
    userSetting() {
      this.tabs = "userSetting";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  &__top {
    height: 70px;
    margin: 0 3px;
    margin-bottom: 10px;
  }
  &__body {
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
}

.el-dialog__header {
  border-bottom: 1px solid rgb(222, 222, 222);
}
.el-dialog__body {
  padding: 10px 20px;
}
.el-form-item--medium .el-form-item__content,
.el-form-item--medium .el-form-item__label {
  line-height: 30px;
}
.el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
  padding-top: 10px;
  border-bottom: 1px solid rgb(222, 222, 222);
}
.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
.el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 0;
}
</style>
