import request from "../../utils/request";

/**标签列表
 *
 * @param params
 * @returns {*}
 */
export function labelList(params) {
  return request({
    url: "/api/repository/repGoodsLabel/list",
    method: "get",
    params: params,
  });
}

/**根据id查询标签
 *
 * @param sid
 * @returns {*}
 */
export function getLabelById(sid) {
  return request({
    url: "/api/repository/repGoodsLabel/get/" + sid,
    method: "get",
  });
}

/**添加标签
 *
 * @param data
 * @returns {*}
 */
export function addLabel(data) {
  return request({
    url: "/api/repository/repGoodsLabel/add",
    method: "post",
    data: data,
  });
}

/**更新标签
 *
 * @param data
 * @returns {*}
 */
export function updateLabel(data) {
  return request({
    url: "/api/repository/repGoodsLabel/update",
    method: "put",
    data: data,
  });
}

/**选择删除标签
 *
 * @param sids
 * @returns {*}
 */
export function deleteLabels(sids) {
  return request({
    url: "/api/repository/repGoodsLabel/deletes/" + sids,
    method: "delete",
  });
}
