<template>
  <div class="user box-shadow">
    <div class="user__body padding10">
      <div class="fs20 fWB borderBottom paddingBottom10">修改账号</div>
      <div class="margin10 bcGrey marginTop20 padding10 box-shadow flexGrow1 flex directionColumn">
        <div class="flex">
          <div class="wFP padding10">
            <div class="fs15 marginBottom10">基本信息</div>
            <el-form ref="form" label-position="left" :model="form" label-width="80px">
              <el-form-item label="账号">
                <el-input v-model="form.phonenumber" disabled />
              </el-form-item>
              <el-form-item label="手机号码">
                <el-input v-model="form.phonenumber"></el-input>
              </el-form-item>
              <el-form-item label="姓名">
                <el-input v-model="form.nickName"></el-input>
              </el-form-item>
              <el-form-item label="账号类型">
                <el-select v-model="form.userType" placeholder="请选择账号类型" @change="changeType">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="form.userType === '1'" label="加盟商">
                <el-select v-model="merchantParams.singleMerchantId">
                  <el-option v-for="item in merchantTree" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="form.userType === '2'" label="所在商户">
                <el-select v-model="merchantParams.merchantId" @change="changeMerchantUser">
                  <el-option v-for="item in merchantList" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
                </el-select>
                <el-select v-model="merchantParams.merchantUserId">
                  <el-option v-for="item in merchantUserList" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色权限">
                <el-select v-model="form.role" placeholder="请选择角色">
                  <el-option v-for="item in roleOptions" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="wFP paddingLeft5 padding10" style="display: flex; flex-direction: column; align-items: center">
            <div class="bcWhite h250 w250 marginBottom20">
              <el-upload ref="upload" class="avatar-uploader" :action="upload.url" :headers="upload.headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon w250 h250"></i>
              </el-upload>
            </div>
            <el-button class="marginBottom10" type="primary" plain @click="triggerUpload">更换头像</el-button>
            <el-switch v-model="form.status" class="marginBottom20" inactive-text="账号状态" />
          </div>
        </div>
        <div class="flex paddingLeft10 paddingRight10" style="display: flex; align-items: center; justify-content: center">
          <span>所在门店 共有 {{ storeList.length }} 家门店</span>
          <el-button type="primary" plain class="marginLeftAuto" icon="el-icon-circle-plus-outline" @click="openSelectStoreHandle">添加门店</el-button>
          <el-button type="danger" plain icon="el-icon-circle-close" @click="cleanUp">清除门店</el-button>
        </div>
        <div class="border margin10 padding10 flexGrow1 scrollbar">
          <el-tag v-for="tag in storeList" :key="tag.cname" class="marginRight5" closable :type="tag.sid" @close="onClose(tag)">
            {{ tag.cname }}
          </el-tag>
        </div>
      </div>
      <div class="margin10 bcGrey h60 padding10 box-shadow marginTop10" style="display: flex; justify-content: flex-end">
        <el-button size="medium" @click="cancel">取 消</el-button>
        <el-button type="primary" @click="onSubmit">提 交</el-button>
      </div>
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="选择门店" :visible.sync="dialogVisible" width="40%" :before-close="handleClose" append-to-body>
      <userAddStore v-if="dialogVisible" :merchant-id="merchantIdFilter" @addStore="addStore" @onCancel="handleClose" />
    </el-dialog>
  </div>
</template>

<script>
import userAddStore from "@/views/settings/userAddStore.vue";
import lodash from "lodash";
import { getToken } from "@/utils/auth";

export default {
  name: "UserEdit",
  components: {
    userAddStore,
  },
  props: {
    userId: {
      type: [String, Number], // 根据需要指定数据类型
      default: null, // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      merchantParams: {
        singleMerchantId: "",
        merchantId: "",
        merchantUserId: "",
      },
      merchantTree: [],
      merchantList: [],
      merchantUserList: [],
      dialogVisible: false,
      roleOptions: [],
      typeOptions: [
        {
          value: "0",
          label: "系统账号",
        },
        {
          value: "1",
          label: "加盟商账号",
        },
        {
          value: "2",
          label: "商户账号",
        },
      ],
      imageUrl: "",
      form: {
        userType: "0",
        nickName: "",
        phonenumber: "",
        role: "",
        merchant: "",
        status: true,
        merchantId: "",
      },
      storeList: [],
      merchantIdFilter: "",
      isFirst: true,
      upload: {
        headers: { Authorization: getToken() },
        url: `${this.baseUrls.file}/upload?businessId=avatar`,
      },
      fileData: {},
    };
  },
  created() {
    if (this.isFirst) this.selectUser(this.userId);
  },
  mounted() {
    this.getRoleList();
  },
  methods: {
    /**
     * 获取权属关系
     */
    selectMerchantTree(pid) {
      let url = `${this.baseUrls.merchant}/queryList`;
      let params = {
        rParentId: pid,
        bValid: 1,
        bDelete: 0,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.merchantTree = lodash.orderBy(res, ["nIndex", "sid"], ["asc", "asc"]);
        this.merchantParams.singleMerchantId = "";
        if (!this.isFirst) {
          if (this.merchantTree.length > 0) {
            this.merchantParams.singleMerchantId = this.merchantTree[0].sid;
          }
        } else {
          this.merchantParams.singleMerchantId = this.form.merchantId == "all" ? this.merchantTree[0].sid : this.form.merchantId;
        }
        this.isFirst = false;
      });
    },
    queryMerchant(pid) {
      let url = `${this.baseUrls.merchant}/queryList`;
      let params = {
        rParentId: pid,
        bValid: 1,
        bDelete: 0,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.merchantList = lodash.orderBy(res, ["nIndex", "sid"], ["asc", "asc"]);
        if (!this.isFirst) {
          if (this.merchantList.length > 0) {
            this.merchantParams.merchantId = this.merchantList[0].sid;
            this.queryMerchantUser(this.merchantList[0].sid);
          }
        } else {
          this.queryMerchantUser(this.merchantParams.merchantId);
        }
        this.isFirst = false;
      });
    },
    queryMerchantUser(pid) {
      let url = `${this.baseUrls.merchant}/queryList`;
      let params = {
        rParentId: pid,
        bValid: 1,
        bDelete: 0,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.merchantUserList = lodash.orderBy(res, ["nIndex", "sid"], ["asc", "asc"]);
        if (!this.merchantParams.merchantUserId) {
          if (this.merchantUserList.length > 0) {
            this.merchantParams.merchantUserId = this.merchantUserList[0].sid;
          }
        } else {
          this.merchantParams.merchantUserId = this.form.merchantId;
        }
      });
    },
    changeMerchantUser(p) {
      this.merchantParams.merchantUserId = "";
      this.queryMerchantUser(p);
    },
    selectUser() {
      let url = `${this.baseUrls.user}/${this.userId}`;
      this.topsAjax.get(url).then(async (res) => {
        this.form.userType = res.userType;
        this.form.phonenumber = res.phonenumber;
        this.form.nickName = res.nickName;
        this.form.merchantId = res.merchantId;
        this.imageUrl = res.avatar;

        this.form.status = res.status == 0 ? true : false;
        if (res.userType == 1) {
          this.selectMerchantTree(0);
          this.merchantParams.singleMerchantId = res.merchantId;
        } else if (res.userType == 2) {
          this.queryMerchant(0);
          this.merchantParams.merchantUserId = res.merchantId;
          await this.getMerchant(res.merchantId);
        }
        if (res.roles) this.form.role = res.roles[0].roleId;
        if (res.stores) this.storeList = res.stores;
      });
    },
    async getMerchant(sid) {
      let url = `${this.baseUrls.merchant}/selectById`;
      let params = {
        sid: sid,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.merchantParams.merchantId = res.rparentId;
      });
    },
    onClose(deletedTag) {
      this.storeList = this.storeList.filter((tag) => tag.cname !== deletedTag.cname);
    },
    addStore(value) {
      this.storeList = value;
      this.handleClose();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    cleanUp() {
      this.storeList = [];
    },
    /**
     * 获取角色列表数据
     */
    async getRoleList() {
      this.roleOptions = [];
      await this.topsAjax.get("/api/system/role/list").then((response) => {
        let data = [];
        if (this.form.userType === "1" || this.form.userType === "2") {
          data = lodash.filter(response, (p) => {
            return !p.admin;
          });
        } else {
          data = response;
        }
        data = lodash.chain(data).orderBy("roleId", "asc").value();
        this.roleOptions = data;
      });
    },
    onSubmit() {
      let merchantId = "";
      if (this.form.userType === "0") {
        merchantId = "all";
      } else if (this.form.userType === "1") {
        merchantId = this.merchantParams.singleMerchantId;
      } else if (this.form.userType === "2") {
        merchantId = this.merchantParams.merchantUserId;
      }
      let data = {
        merchantId: merchantId,
        userName: this.form.phonenumber,
        nickName: this.form.nickName,
        password: this.form.phonenumber,
        userType: this.form.userType,
        phonenumber: this.form.phonenumber,
        status: this.form.status === true ? 0 : 1,
        roleIds: [this.form.role],
        stores: this.storeList,
        avatar: this.imageUrl,
      };
      let url = "";
      let params = {};

      data.userId = this.userId;
      url = `${this.baseUrls.user}/edit`;
      params = data;

      if (this.checkParams(data.userName)) {
        this.$message.error("手机号不能为空！");
        return;
      } else if (this.checkParams(data.nickName)) {
        this.$message.error("姓名不能为空！");
        return;
      } else if (this.checkParams(data.merchantId)) {
        this.$message.error("加盟商或加盟商户未选择！");
        return;
      } else if (data.roleIds == null) {
        this.$message.error("未选择角色权限！");
        return;
      }

      this.topsAjax.post(url, params).then((response) => {
        this.$message.success("修改成功");
        this.cancel();
      });
    },
    cancel() {
      this.$emit("userSetting");
    },
    async changeType() {
      await this.getRoleList();
      if (this.form.userType === "1") {
        this.selectMerchantTree(0);
      } else if (this.form.userType === "2") {
        this.queryMerchant(0);
      }
    },
    //存在跨域问题
    handleAvatarSuccess(res, file) {
      this.fileData = res.data;
      this.imageUrl = this.fileData.curl;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      this.imageUrl = URL.createObjectURL(file);
      return isLt2M;
    },
    openSelectStoreHandle() {
      this.dialogVisible = true;
      if (this.form.userType === "0") {
        this.merchantIdFilter = "";
      } else if (this.form.userType === "1") {
        this.merchantIdFilter = this.merchantParams.singleMerchantId;
      } else if (this.form.userType === "2") {
        this.merchantIdFilter = this.merchantParams.merchantUserId;
      }
    },
    checkParams(o) {
      if (o == null || o == "" || o == undefined) {
        return true;
      } else {
        return false;
      }
    },
    triggerUpload() {
      this.$refs.upload.$el.querySelector('input[type="file"]').click();
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
.user {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;

  &__body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 250px;
  height: 250px;
  line-height: 250px;
  text-align: center;
}

.avatar {
  width: 250px;
  height: 250px;
  display: block;
}
</style>
