var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-position": "left", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "时长" } },
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 60, label: "描述文字" },
                model: {
                  value: _vm.form.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "time", $$v)
                  },
                  expression: "form.time",
                },
              }),
            ],
            1
          ),
          _vm.form.tipAmount
            ? _c(
                "el-form-item",
                { attrs: { label: "金额" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 60, label: "描述文字" },
                    model: {
                      value: _vm.form.tipAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tipAmount", $$v)
                      },
                      expression: "form.tipAmount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticClass: "el-form-item-top", attrs: { label: "状态" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.state,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "state", $$v)
                  },
                  expression: "form.state",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }