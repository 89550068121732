<template>
  <!-- 角色管理 -->
  <div class="menu box-shadow padding10">
    <dc-panel title="角色管理">
      <div class="menu">
        <div class="flex marginBottom10">
          <el-input v-model="queryPara.menuName" clearable placeholder="请输入角色名称" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
          </el-input>
          <el-button type="primary" class="marginLeftAuto" @click="addRole">新增角色</el-button>
        </div>
        <dc-datatable ref="list" :url="urlApi" :params="queryPara" tooltip-effect="dark">
          <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="63" />
          <el-table-column align="center" prop="roleName" label="角色名称" width="120">
            <template slot-scope="scope">
              <div class="marginLeft10">
                {{ scope.row.roleName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="角色标识" width="100">
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.roleKey }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="menusStr" label="所属菜单" width="320" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.menusStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="remark" label="角色描述" width="250" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.remark }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="账号状态" width="80">
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.status === "0" ? "启用" : "禁用" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" label="操作" width="150">
            <template #default="scope">
              <el-button type="text" class="green" @click="authority(scope.row)">权限</el-button>
              <el-button type="text" @click="updateRole(scope.row)">编辑</el-button>
              <el-button type="text" class="red" @click="deleteRole(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </dc-datatable>
      </div>
    </dc-panel>
    <el-dialog :title="title" :visible.sync="addRoleVisible" width="400px" append-to-body @close="dialogClose">
      <el-form ref="form" :model="formData" :rules="rules">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="formData.roleName" placeholder="请输入角色名称" :disabled="roleNameDisabled" />
        </el-form-item>
        <el-form-item label="角色标识" prop="roleKey">
          <el-input v-model="formData.roleKey" placeholder="请输入角色标识" />
        </el-form-item>
        <el-form-item label="角色描述" prop="remark">
          <el-input v-model="formData.remark" class="marginLeft10" placeholder="请输入角色描述" />
        </el-form-item>
        <el-form-item label="启用状态" prop="status">
          <el-switch v-model="formData.status" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addRoleVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="权限配置" :visible.sync="setAuthorityVisible" width="400px" append-to-body @open="treeSelect">
      <el-tree ref="tree" :data="menuOptions" show-checkbox node-key="id" default-expand-all :default-checked-keys="checkedKeys" :props="defaultProps" @check-change="checkChange"> </el-tree>

      <!--      <el-form ref="form2" :model="formData2">-->
      <!--        <el-form-item label="权限" prop="menuIds">-->
      <!--          &lt;!&ndash;          <el-cascader v-model="formData2.menuIds" class="marginLeft10" :options="menuOptions" collapse-tags :props="{ multiple: true, value: 'id' }" />&ndash;&gt;-->
      <!--        </el-form-item>-->
      <!--      </el-form>-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="setAuthorityVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAuthority">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "RoleSetting",
  data() {
    return {
      urlApi: `${this.baseUrls.role}/pageList`,
      queryPara: {
        userName: "",
        type: "1",
      },
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      defaultProps: {
        children: "children",
        label: "label",
      },
      addRoleVisible: false,
      setAuthorityVisible: false,
      roleNameDisabled: false,
      menuOptions: [],
      checkedKeys: [],
      isAdd: true,
      title: "",
      roleId: 0,
      formData2: {
        menuIds: [],
      },
      formData: {
        roleName: "",
        roleKey: "",
        roleSort: 0,
        status: true,
        remark: "",
      },
      rules: {
        roleName: [{ required: true, message: "角色名称不能为空", trigger: "blur" }],
        roleKey: [{ required: true, message: "权限字符不能为空", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    dialogClose() {
      this.$refs["form"].resetFields();
    },
    treeSelect() {
      let url = `${this.baseUrls.menu}/treeselect`;
      this.topsAjax.get(url, { type: 1 }).then((response) => {
        this.menuOptions = response;
      });
    },
    checkChange() {
      const d = [...this.$refs.tree.getHalfCheckedKeys(), ...this.$refs.tree.getCheckedKeys()];
      this.formData2.menuIds = d;
    },
    submitAuthority() {
      this.formData2.roleId = this.roleId;
      this.formData2.menuIds = [...new Set(this.formData2.menuIds.flat())];
      this.topsAjax.post(`${this.baseUrls.role}/setRoleAndMenus`, this.formData2).then((response) => {
        this.$message.success("新增成功");
        this.setAuthorityVisible = false;
        this.doSearch();
      });
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let methodPath = "/edit";
          if (this.isAdd) {
            methodPath = "/add";
          }
          this.formData.status = this.formData.status ? "0" : "1";
          this.topsAjax.post(`${this.baseUrls.role}` + methodPath, this.formData).then((response) => {
            this.$message.success("新增成功");
            this.addRoleVisible = false;
            this.doSearch();
          });
        }
      });
    },
    /**
     * 获取子节点
     * @param list
     * @param parentId
     * @returns {*}
     */
    getChildren(list, parentId) {
      let d = 0;
      list.forEach((item) => {
        if (item.id === parentId) {
          if (item.children) {
            d = item.children;
          } else {
            d = item.id;
          }
        }
      });
      return d;
    },
    /**
     * 获取角色id
     * @param list
     * @param parentIds
     * @returns {*}
     */
    getMenuId(list, parentIds) {
      let d = list;
      for (let i = 0; i < parentIds.length; ) {
        d = this.getChildren(d, parentIds[i]);
        i++;
        if (i === parentIds.length) {
          if (d.length === undefined) {
            return Number(String(d) + "1");
          } else {
            let maxId = 0;
            d.forEach((item) => {
              maxId = item.id > maxId ? item.id : maxId;
            });
            return ++maxId;
          }
        }
      }
    },
    addRole() {
      this.title = "新增角色";
      this.clearForm();
      this.isAdd = true;
      this.roleNameDisabled = false;
      this.addRoleVisible = true;
    },
    updateRole(menu) {
      this.formData = JSON.parse(JSON.stringify(menu));
      this.isAdd = false;
      this.formData.status = menu.status === "0";
      this.title = "修改角色";
      this.roleNameDisabled = true;
      this.addRoleVisible = true;
    },
    authority(menu) {
      let menuIds = menu.menuIds.filter((item) => item % 10 !== 0);
      this.checkedKeys = menuIds;
      this.formData2.menuIds = menu.menuIds;
      this.roleId = menu.roleId;
      this.setAuthorityVisible = true;
    },
    /**
     * 删除
     * @param menu
     */
    deleteRole(menu) {
      this.$confirm('是否确认删除角色："' + menu.roleName + '"？')
        .then(() => {
          let p = [];
          p.push(menu.roleId);
          let url = `${this.baseUrls.role}/del/` + p;
          this.topsAjax.get(url).then((response) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.addRoleVisible = false;
            this.doSearch();
          });
        })
        .catch(() => {});
    },
    doSearch() {
      this.$refs.list.load();
    },
    clearForm() {
      this.formData = {
        roleName: "",
        roleKey: "",
        roleSort: 0,
        status: true,
        remark: "",
      };
    },
    indexMethod(index) {
      let page = this.$refs.list.page;
      return index + 1 + (page.currentPage - 1) * page.pageSize;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;

  &__body {
    overflow: auto;
    height: 100%;
  }
}
.green {
  color: #67c23a;
}
</style>
