var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "container__left bcWhite margin10 box-shadow" }, [
      _c(
        "div",
        { staticClass: "leftTaps" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "tops",
              attrs: { "tab-position": "left" },
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            _vm._l(_vm.tapArray, function (tab) {
              return _c("el-tab-pane", {
                key: tab.component,
                attrs: { label: tab.menuName, name: tab.component },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "container__right margin10 borderRadius5" },
      [
        _vm.tabs === "merchantSetting" ? _c("merchant") : _vm._e(),
        _vm.tabs === "userIndex" ? _c("userIndex") : _vm._e(),
        _vm.tabs === "notificationsSettings" ? _c("notifications") : _vm._e(),
        _vm.tabs === "deliverySettings" ? _c("delivery") : _vm._e(),
        _vm.tabs === "log" ? _c("log") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }