var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu box-shadow padding10" },
    [
      _c("dc-panel", { attrs: { title: "菜单管理" } }, [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c(
              "div",
              { staticClass: "flex marginBottom10" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { clearable: "", placeholder: "请输入菜单名称" },
                    model: {
                      value: _vm.queryPara.menuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryPara, "menuName", $$v)
                      },
                      expression: "queryPara.menuName",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.doSearch },
                      slot: "append",
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marginLeftAuto",
                    attrs: { type: "primary" },
                    on: { click: _vm.addMenu },
                  },
                  [_vm._v("新增菜单")]
                ),
              ],
              1
            ),
            _c(
              "dc-datatable",
              {
                ref: "list",
                attrs: { url: _vm.urlApi, params: _vm.queryPara },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    type: "index",
                    label: "序号",
                    index: _vm.indexMethod,
                    width: "63",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "menuName",
                    label: "菜单名称",
                    width: "123",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "marginLeft10" }, [
                            _vm._v(" " + _vm._s(scope.row.menuName) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "path",
                    label: "路由地址",
                    width: "297",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(" " + _vm._s(scope.row.path) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "component",
                    label: "组件名称",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(" " + _vm._s(scope.row.component) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "menuType",
                    label: "菜单类型",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.menuType === "M"
                                    ? "目录"
                                    : scope.row.menuType === "C"
                                    ? "菜单"
                                    : "按钮"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "perms",
                    label: "权限标识",
                    width: "220",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [
                            _vm._v(" " + _vm._s(scope.row.perms) + " "),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "address",
                    label: "操作",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "red",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteMenu(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.addMenuVisible,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addMenuVisible = $event
            },
            open: _vm.treeSelect,
            close: _vm.dialogClose,
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.formData, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "父菜单", prop: "parentId" } },
                [
                  _c("el-cascader", {
                    staticClass: "marginLeft10",
                    attrs: {
                      options: _vm.menuOptions,
                      props: { checkStrictly: true, value: "id" },
                    },
                    model: {
                      value: _vm.formData.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "parentId", $$v)
                      },
                      expression: "formData.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单名称", prop: "menuName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标签名称" },
                    model: {
                      value: _vm.formData.menuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "menuName", $$v)
                      },
                      expression: "formData.menuName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单类型", prop: "menuType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择菜单类型" },
                      model: {
                        value: _vm.formData.menuType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "menuType", $$v)
                        },
                        expression: "formData.menuType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "菜单", value: "C" } }),
                      _c("el-option", { attrs: { label: "按钮", value: "F" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.formData.menuType === "C"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "路由地址", prop: "path" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入路由地址" },
                        model: {
                          value: _vm.formData.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "path", $$v)
                          },
                          expression: "formData.path",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.menuType === "C"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "组件名称", prop: "component" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入组件名称" },
                        model: {
                          value: _vm.formData.component,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "component", $$v)
                          },
                          expression: "formData.component",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.menuType === "F"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "权限标识", prop: "perms" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入权限标识" },
                        model: {
                          value: _vm.formData.perms,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "perms", $$v)
                          },
                          expression: "formData.perms",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.menuType === "M"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "菜单图标", prop: "icon" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入菜单图标" },
                        model: {
                          value: _vm.formData.icon,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "icon", $$v)
                          },
                          expression: "formData.icon",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "显示顺序", prop: "orderNum" } },
                [
                  _c("el-input", {
                    staticClass: "marginLeft10",
                    attrs: { type: "number", placeholder: "请输入显示顺序" },
                    model: {
                      value: _vm.formData.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "orderNum", $$v)
                      },
                      expression: "formData.orderNum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addMenuVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }