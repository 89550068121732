var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "deliverySettings box-shadow" },
    [
      _c("div", { staticClass: "deliverySettings__body padding10" }, [
        _c("div", { staticClass: "fs20 fWB borderBottom paddingBottom10" }, [
          _vm._v("手动发单"),
        ]),
        _c(
          "div",
          { staticClass: "marginTop10 orderState h60" },
          [
            _vm._m(0),
            _c(
              "el-button",
              {
                attrs: { type: "text" },
                on: {
                  click: function ($event) {
                    return _vm.openDialog(1)
                  },
                },
              },
              [_vm._v("设置 >")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "fs20 fWB borderBottom paddingBottom10 marginTop10 marginBottom10",
          },
          [_vm._v("自动发单")]
        ),
        _c(
          "div",
          { staticClass: "marginBottom10 orderState h70" },
          [
            _c("div", [
              _c("div", [_vm._v("及时单自动发单")]),
              _c("div", { staticClass: "fs11" }, [
                _vm._v(
                  "根据设置的时间来单后自动呼叫配送，如有多个将发最便宜的一个配送"
                ),
              ]),
              _c(
                "div",
                { staticClass: "fs11" },
                [
                  _c("span", { staticClass: "fs11" }, [_vm._v("顾客下单后")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.deliveryData.ntimelyAutoSendTime)),
                  ]),
                  _c("span", { staticClass: "fs11" }, [_vm._v("分钟")]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog(2, {
                            type: 1,
                            state: _vm.deliveryData.btimelyAutoSend,
                            time: _vm.deliveryData.ntimelyAutoSendTime,
                          })
                        },
                      },
                    },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
            ]),
            _c("el-switch", {
              staticClass: "floatRight",
              attrs: { disabled: "" },
              model: {
                value: _vm.deliveryData.btimelyAutoSend,
                callback: function ($$v) {
                  _vm.$set(_vm.deliveryData, "btimelyAutoSend", $$v)
                },
                expression: "deliveryData.btimelyAutoSend",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "marginBottom10 orderState h70" },
          [
            _c("div", [
              _c("div", [_vm._v("预订单自动配送")]),
              _c("div", { staticClass: "fs11" }, [
                _vm._v(
                  "设置的时长为预约单期望送达前多长时间自动呼叫配送，如不设置将按系统建议时间"
                ),
              ]),
              _c(
                "div",
                { staticClass: "fs11" },
                [
                  _c("span", { staticClass: "fs11" }, [
                    _vm._v("系统建议发货时间"),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.deliveryData.nreservationAutoDeliveryTime)
                    ),
                  ]),
                  _c("span", { staticClass: "fs11" }, [_vm._v("分钟")]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog(2, {
                            type: 2,
                            state: _vm.deliveryData.breservationAutoDelivery,
                            time: _vm.deliveryData.nreservationAutoDeliveryTime,
                          })
                        },
                      },
                    },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
            ]),
            _c("el-switch", {
              staticClass: "floatRight",
              attrs: { disabled: "" },
              model: {
                value: _vm.deliveryData.breservationAutoDelivery,
                callback: function ($$v) {
                  _vm.$set(_vm.deliveryData, "breservationAutoDelivery", $$v)
                },
                expression: "deliveryData.breservationAutoDelivery",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "marginBottom10 orderState h60" },
          [
            _c("div", [
              _c("div", [_vm._v("自动加小费")]),
              _c(
                "div",
                { staticClass: "fs11" },
                [
                  _c("span", { staticClass: "fs11" }, [_vm._v("配送下单")]),
                  _c("span", [_vm._v(_vm._s(_vm.deliveryData.nautoTipTime))]),
                  _c("span", { staticClass: "fs11" }, [
                    _vm._v(
                      "分钟后，如未接单加小费" +
                        _vm._s(_vm.deliveryData.ntipAmount) +
                        "元"
                    ),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog(2, {
                            type: 3,
                            state: _vm.deliveryData.bautoTip,
                            time: _vm.deliveryData.nautoTipTime,
                            tipAmount: _vm.deliveryData.ntipAmount,
                          })
                        },
                      },
                    },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
            ]),
            _c("el-switch", {
              staticClass: "floatRight",
              attrs: { disabled: "" },
              model: {
                value: _vm.deliveryData.bautoTip,
                callback: function ($$v) {
                  _vm.$set(_vm.deliveryData, "bautoTip", $$v)
                },
                expression: "deliveryData.bautoTip",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "fs20 fWB borderBottom paddingBottom10 marginTop10 marginBottom10",
          },
          [_vm._v("催单设置")]
        ),
        _c(
          "div",
          { staticClass: "marginBottom10 orderState h60" },
          [
            _c("div", [
              _c("div", [_vm._v("自动回复催单")]),
              _c(
                "div",
                { staticClass: "fs11 marginTop5" },
                [
                  _vm._v(" 有催单时将根据当前订单状态自动回复顾客 "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog(3, {
                            type: 1,
                            state: _vm.deliveryData.bautoReplyReminder,
                            message: _vm.deliveryData.creplyReminderMessage,
                            title: "回复内容",
                            isState: true,
                          })
                        },
                      },
                    },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
            ]),
            _c("el-switch", {
              staticClass: "floatRight",
              attrs: { disabled: "" },
              model: {
                value: _vm.deliveryData.bautoReplyReminder,
                callback: function ($$v) {
                  _vm.$set(_vm.deliveryData, "bautoReplyReminder", $$v)
                },
                expression: "deliveryData.bautoReplyReminder",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "marginBottom10 orderState h60" },
          [
            _c("div", [
              _c("div", [_vm._v("自动发送催单短信")]),
              _c(
                "div",
                { staticClass: "fs11 marginTop5" },
                [
                  _vm._v(" 顾客催单后系统自动发送一条催单给骑手 "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialog(3, {
                            type: 2,
                            state: _vm.deliveryData.bautoSendReminder,
                            message: _vm.deliveryData.csendReminderMessage,
                            title: "催单内容",
                            isState: true,
                          })
                        },
                      },
                    },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
            ]),
            _c("el-switch", {
              staticClass: "floatRight",
              attrs: { disabled: "" },
              model: {
                value: _vm.deliveryData.bautoSendReminder,
                callback: function ($$v) {
                  _vm.$set(_vm.deliveryData, "bautoSendReminder", $$v)
                },
                expression: "deliveryData.bautoSendReminder",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "fs20 fWB borderBottom paddingBottom10 marginTop10 marginBottom10",
          },
          [_vm._v("备注设置")]
        ),
        _c(
          "div",
          { staticClass: "marginBottom10 orderState h60" },
          [
            _vm._m(1),
            _c(
              "el-button",
              {
                attrs: { type: "text", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.openDialog(3, {
                      type: 3,
                      notesArray: _vm.deliveryData.acommonNotes,
                    })
                  },
                },
              },
              [_vm._v("设置 >")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "marginBottom10 orderState h60" },
          [
            _vm._m(2),
            _c(
              "el-button",
              {
                attrs: { type: "text", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.openDialog(3, {
                      type: 4,
                      message: _vm.deliveryData.cdefaultNotes,
                      title: "默认备注",
                    })
                  },
                },
              },
              [_vm._v("设置 >")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "配送下单模式",
            visible: _vm.deliverySetDialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deliverySetDialogVisible = $event
            },
          },
        },
        [
          _vm.deliverySetDialogVisible
            ? _c("deliveryOrderSet", {
                ref: "deliveryOrderSet",
                attrs: { "set-data": _vm.deliveryData },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "设置",
            visible: _vm.deliveryOpen,
            width: "30%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deliveryOpen = $event
            },
          },
        },
        [
          _vm.deliveryOpen
            ? _c("deliverySet", {
                ref: "deliverySet",
                attrs: { "set-data": _vm.setData },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "备注设置",
            visible: _vm.remarkSetOpen,
            width: "30%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.remarkSetOpen = $event
            },
          },
        },
        [
          _vm.remarkSetOpen
            ? _c("remarkSet", {
                ref: "remarkSet",
                attrs: { "set-data": _vm.setData },
                on: { handleClose: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", [_vm._v("配送下单选择模式")]),
      _c("div", { staticClass: "fs11" }, [
        _vm._v("选择发单时选择一个或多个配送"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", [_vm._v("设置常用备注")]),
      _c("div", { staticClass: "fs11 marginTop5" }, [
        _vm._v("发单时默认显示经常输入的六个备注"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", [_vm._v("设置默认备注")]),
      _c("div", { staticClass: "fs11 marginTop5" }, [
        _vm._v("设置后每次发单都会默认显示备注"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }