var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user box-shadow" },
    [
      _c("div", { staticClass: "user__body padding10" }, [
        _c("div", { staticClass: "fs20 fWB borderBottom paddingBottom10" }, [
          _vm._v("修改账号"),
        ]),
        _c(
          "div",
          {
            staticClass:
              "margin10 bcGrey marginTop20 padding10 box-shadow flexGrow1 flex directionColumn",
          },
          [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "wFP padding10" },
                [
                  _c("div", { staticClass: "fs15 marginBottom10" }, [
                    _vm._v("基本信息"),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        "label-position": "left",
                        model: _vm.form,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账号" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.phonenumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phonenumber", $$v)
                              },
                              expression: "form.phonenumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.phonenumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phonenumber", $$v)
                              },
                              expression: "form.phonenumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.nickName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nickName", $$v)
                              },
                              expression: "form.nickName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "账号类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择账号类型" },
                              on: { change: _vm.changeType },
                              model: {
                                value: _vm.form.userType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "userType", $$v)
                                },
                                expression: "form.userType",
                              },
                            },
                            _vm._l(_vm.typeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.userType === "1",
                              expression: "form.userType === '1'",
                            },
                          ],
                          attrs: { label: "加盟商" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.merchantParams.singleMerchantId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merchantParams,
                                    "singleMerchantId",
                                    $$v
                                  )
                                },
                                expression: "merchantParams.singleMerchantId",
                              },
                            },
                            _vm._l(_vm.merchantTree, function (item) {
                              return _c("el-option", {
                                key: item.sid,
                                attrs: { label: item.cname, value: item.sid },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.userType === "2",
                              expression: "form.userType === '2'",
                            },
                          ],
                          attrs: { label: "所在商户" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.changeMerchantUser },
                              model: {
                                value: _vm.merchantParams.merchantId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merchantParams,
                                    "merchantId",
                                    $$v
                                  )
                                },
                                expression: "merchantParams.merchantId",
                              },
                            },
                            _vm._l(_vm.merchantList, function (item) {
                              return _c("el-option", {
                                key: item.sid,
                                attrs: { label: item.cname, value: item.sid },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.merchantParams.merchantUserId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merchantParams,
                                    "merchantUserId",
                                    $$v
                                  )
                                },
                                expression: "merchantParams.merchantUserId",
                              },
                            },
                            _vm._l(_vm.merchantUserList, function (item) {
                              return _c("el-option", {
                                key: item.sid,
                                attrs: { label: item.cname, value: item.sid },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "角色权限" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择角色" },
                              model: {
                                value: _vm.form.role,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "role", $$v)
                                },
                                expression: "form.role",
                              },
                            },
                            _vm._l(_vm.roleOptions, function (item) {
                              return _c("el-option", {
                                key: item.roleId,
                                attrs: {
                                  label: item.roleName,
                                  value: item.roleId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "wFP paddingLeft5 padding10",
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "bcWhite h250 w250 marginBottom20" },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.upload.url,
                            headers: _vm.upload.headers,
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                          },
                        },
                        [
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.imageUrl },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon w250 h250",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "marginBottom10",
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.triggerUpload },
                    },
                    [_vm._v("更换头像")]
                  ),
                  _c("el-switch", {
                    staticClass: "marginBottom20",
                    attrs: { "inactive-text": "账号状态" },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "flex paddingLeft10 paddingRight10",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                },
              },
              [
                _c("span", [
                  _vm._v(
                    "所在门店 共有 " + _vm._s(_vm.storeList.length) + " 家门店"
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "marginLeftAuto",
                    attrs: {
                      type: "primary",
                      plain: "",
                      icon: "el-icon-circle-plus-outline",
                    },
                    on: { click: _vm.openSelectStoreHandle },
                  },
                  [_vm._v("添加门店")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      plain: "",
                      icon: "el-icon-circle-close",
                    },
                    on: { click: _vm.cleanUp },
                  },
                  [_vm._v("清除门店")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "border margin10 padding10 flexGrow1 scrollbar" },
              _vm._l(_vm.storeList, function (tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag.cname,
                    staticClass: "marginRight5",
                    attrs: { closable: "", type: tag.sid },
                    on: {
                      close: function ($event) {
                        return _vm.onClose(tag)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(tag.cname) + " ")]
                )
              }),
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "margin10 bcGrey h60 padding10 box-shadow marginTop10",
            staticStyle: { display: "flex", "justify-content": "flex-end" },
          },
          [
            _c(
              "el-button",
              { attrs: { size: "medium" }, on: { click: _vm.cancel } },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
              [_vm._v("提 交")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "选择门店",
            visible: _vm.dialogVisible,
            width: "40%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("userAddStore", {
                attrs: { "merchant-id": _vm.merchantIdFilter },
                on: { addStore: _vm.addStore, onCancel: _vm.handleClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }