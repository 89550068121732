<template>
  <!-- 商户管理-->
  <div class="merchant box-shadow padding10">
    <dc-panel title="商户管理">
      <div class="merchant">
        <div class="flex marginBottom10">
          <el-input v-model="merchantName" clearable placeholder="请输入商户名称" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="selectMerchantTree"></el-button>
          </el-input>
          <el-button type="primary" class="marginLeftAuto" @click="handleOpen">新增商户</el-button>
        </div>
        <div class="flex1">
          <el-table :data="tableData" height="200" row-key="sid" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column prop="c_name" label="商户名称" />
            <el-table-column align="center" label="状态" width="150">
              <template #default="scope">
                {{ scope.row.b_state === 0 ? "启用" : "禁止" }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" :resizable="false" width="150">
              <template #default="scope">
                <el-button type="text" @click="handleOpen(scope.row)">新增</el-button>
                <el-button type="text" @click="handUpdate(scope.row)">修改</el-button>
                <el-button class="red" type="text" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </dc-panel>
    <el-dialog :close-on-click-modal="false" :title="dialogData.title" :visible.sync="dialogData.visible" width="700px" :before-close="handleClose" append-to-body>
      <merchantInfo v-if="dialogData.visible" ref="merchantInfo" :parent-id="parentId" :update-id="updateId" @handleClose="handleClose" @clickback="handleClose" />
    </el-dialog>
  </div>
</template>
<script>
import merchantInfo from "./merchantInfo.vue";
import moment from "moment/moment";
export default {
  name: "Merchant",
  components: {
    merchantInfo,
  },
  data() {
    return {
      merchantName: null,
      tableData: [],
      dialogData: {
        visible: false,
        title: "",
      },
      updateId: "",
      parentId: "",
    };
  },
  mounted() {
    this.selectMerchantTree();
  },
  methods: {
    /**
     * 获取权属关系
     */
    selectMerchantTree() {
      let url = `${this.baseUrls.merchant}/selectMerchantTree`;
      this.topsAjax.get(url, { merchantName: this.merchantName }).then((res) => {
        this.tableData = res;
      });
    },
    /**
     * 軟刪除
     * @param row
     */
    handleDelete(row) {
      this.$confirm('是否确认删除商户"' + row.cname + '"？')
        .then((_) => {
          const url = `${this.baseUrls.merchant}/deleteById`;
          this.topsAjax.post(url, { sid: row.sid }).then((response) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.$refs.list.load();
          });
        })
        .catch((_) => {});
    },
    handUpdate(row) {
      this.dialogData.title = "商户修改";
      this.updateId = row.sid;
      this.dialogData.visible = true;
    },
    handleOpen(row) {
      this.dialogData.title = "商户修改";
      if (row) {
        this.parentId = row.sid;
      }
      this.dialogData.visible = true;
    },
    //关闭弹窗
    handleClose() {
      this.selectMerchantTree();
      this.dialogData.visible = false;
      this.dialogData.title = "";
      this.parentId = "";
      this.updateId = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.merchant {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;
  &__body {
    overflow: auto;
    height: 100%;
  }
}
</style>
