<template>
  <!-- 账号管理 -->
  <div class="user box-shadow padding10">
    <dc-panel title="账号管理">
      <div class="user">
        <div class="flex marginBottom10">
          <el-input v-model="queryPara.userName" clearable placeholder="请输入手机号码" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
          </el-input>
          <el-button type="primary" class="marginLeftAuto" @click="addUser">新增账号</el-button>
        </div>
        <dc-datatable ref="list" :url="urlApi" :params="queryPara">
          <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="100" />
          <el-table-column align="center" label="账号类型" width="170">
            <template slot-scope="scope">
              <div class="marginLeft10">
                {{ scope.row.userType === "0" ? "系统账号" : scope.row.userType === "1" ? "加盟商账号" : "商户账号" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="userName" label="账号" width="200">
            <template slot-scope="scope">
              <div class="marginLeft10">
                {{ scope.row.userName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="nickName" label="用户名" width="200">
            <template slot-scope="scope">
              <div class="marginLeft10">
                {{ scope.row.nickName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="remark" label="角色权限" width="160">
            <template slot-scope="scope">
              {{ scope.row.roleName }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态" width="100">
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.status === "1" ? "禁止" : "启用" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" label="操作" width="160">
            <template #default="scope">
              <el-button type="text" @click="resetPwd(scope.row)">重置密码</el-button>
              <el-button type="text" @click="updateUser(scope.row.userId)">编辑</el-button>
              <el-button type="text" class="red" @click="deleteUser(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </dc-datatable>
      </div>
    </dc-panel>
  </div>
</template>

<script>
import moment from "moment/moment";
import { deleteLabels } from "@/api/goods/goodsLabel";

export default {
  name: "UserSetting",
  data() {
    return {
      urlApi: `${this.baseUrls.user}/queryPageList`,
      queryPara: {
        userName: "",
      },
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {},
  methods: {
    /**
     * 跳转添加账号
     * @constructor
     */
    addUser() {
      this.$emit("addUser");
    },
    updateUser(userId) {
      this.$emit("updateUser", userId);
    },
    /**
     * 删除
     * @param user
     */
    deleteUser(user) {
      this.$confirm('是否确认删除账号"' + user.userName + '"？')
        .then(() => {
          let url = `${this.baseUrls.user}/delUser`;
          user.delFlag = "2";
          this.topsAjax.post(url, user).then((response) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.doSearch();
          });
        })
        .catch(() => {});
    },
    doSearch() {
      this.$refs.list.load();
    },
    doReset() {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    resetPwd(row) {
      this.$confirm('是否确认重置账号"' + row.nickName + '"的密码？')
        .then(() => {
          let data = { userId: row.userId, userName: row.userName.trim(), password: row.userName.trim(), editPwd: 0 };
          let url = `${this.baseUrls.user}/edit`;
          this.topsAjax.post(url, data).then((response) => {
            this.$message({
              message: "设置密码成功",
              type: "success",
            });
            this.doSearch();
          });
        })
        .catch(() => {});
    },
    handleClick(tab, event) {
      this.queryPara.startTime = moment().format("YYYY-MM-DD");
      this.queryPara.endTime = moment().format("YYYY-MM-DD");
      $table.resetHeight();
      this.doSearch();
    },
    getMonth(value) {
      if (value === 1) {
        this.queryPara.startTime = moment().startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().endOf("months").format("YYYY-MM-DD");
      } else if (value === 2) {
        this.queryPara.startTime = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      } else if (value === 3) {
        this.queryPara.startTime = moment().subtract(3, "months").format("YYYY-MM-DD");
        this.queryPara.endTime = moment().format("YYYY-MM-DD");
      }
      this.doSearch();
    },
    indexMethod(index) {
      let page = this.$refs.list.page;
      return index + 1 + (page.currentPage - 1) * page.pageSize;
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;
  &__body {
    overflow: auto;
    height: 100%;
  }
}
</style>
