import { render, staticRenderFns } from "./merchant.vue?vue&type=template&id=bec5b4ec&scoped=true"
import script from "./merchant.vue?vue&type=script&lang=js"
export * from "./merchant.vue?vue&type=script&lang=js"
import style0 from "./merchant.vue?vue&type=style&index=0&id=bec5b4ec&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bec5b4ec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\space\\banling-web-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bec5b4ec')) {
      api.createRecord('bec5b4ec', component.options)
    } else {
      api.reload('bec5b4ec', component.options)
    }
    module.hot.accept("./merchant.vue?vue&type=template&id=bec5b4ec&scoped=true", function () {
      api.rerender('bec5b4ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/settings/merchant.vue"
export default component.exports