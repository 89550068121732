<template>
  <!-- 菜单管理 -->
  <div class="menu box-shadow padding10">
    <dc-panel title="菜单管理">
      <div class="menu">
        <div class="flex marginBottom10">
          <el-input v-model="queryPara.menuName" clearable placeholder="请输入菜单名称" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
          </el-input>
          <el-button type="primary" class="marginLeftAuto" @click="addMenu">新增菜单</el-button>
        </div>
        <dc-datatable ref="list" :url="urlApi" :params="queryPara">
          <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="63" />
          <el-table-column align="center" prop="menuName" label="菜单名称" width="123">
            <template slot-scope="scope">
              <div class="marginLeft10">
                {{ scope.row.menuName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="path" label="路由地址" width="297">
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.path }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="component" label="组件名称" width="180">
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.component }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="menuType" label="菜单类型" width="100">
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.menuType === "M" ? "目录" : scope.row.menuType === "C" ? "菜单" : "按钮" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="perms" label="权限标识" width="220">
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.perms }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" label="操作" width="100">
            <template #default="scope">
              <!--              <el-button type="text" @click="updateMenu(scope.row)">编辑</el-button>-->
              <el-button type="text" class="red" @click="deleteMenu(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </dc-datatable>
      </div>
    </dc-panel>
    <el-dialog :title="title" :visible.sync="addMenuVisible" width="400px" append-to-body @open="treeSelect" @close="dialogClose">
      <el-form ref="form" :model="formData" :rules="rules">
        <el-form-item label="父菜单" prop="parentId">
          <el-cascader v-model="formData.parentId" class="marginLeft10" :options="menuOptions" :props="{ checkStrictly: true, value: 'id' }"></el-cascader>
        </el-form-item>
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="formData.menuName" placeholder="请输入标签名称" />
        </el-form-item>
        <el-form-item label="菜单类型" prop="menuType">
          <el-select v-model="formData.menuType" placeholder="请选择菜单类型">
            <!--            <el-option label="目录" value="M"></el-option>-->
            <el-option label="菜单" value="C"></el-option>
            <el-option label="按钮" value="F"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formData.menuType === 'C'" label="路由地址" prop="path">
          <el-input v-model="formData.path" placeholder="请输入路由地址" />
        </el-form-item>
        <el-form-item v-if="formData.menuType === 'C'" label="组件名称" prop="component">
          <el-input v-model="formData.component" placeholder="请输入组件名称" />
        </el-form-item>
        <el-form-item v-if="formData.menuType === 'F'" label="权限标识" prop="perms">
          <el-input v-model="formData.perms" placeholder="请输入权限标识" />
        </el-form-item>
        <el-form-item v-if="formData.menuType === 'M'" label="菜单图标" prop="icon">
          <el-input v-model="formData.icon" placeholder="请输入菜单图标" />
        </el-form-item>
        <el-form-item label="显示顺序" prop="orderNum">
          <el-input v-model="formData.orderNum" class="marginLeft10" type="number" placeholder="请输入显示顺序" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addMenuVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MenuSetting",
  data() {
    return {
      urlApi: `${this.baseUrls.menu}/pageList`,
      queryPara: {
        userName: "",
        type: "1",
      },
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      addMenuVisible: false,
      menuOptions: [],
      title: "",
      formData: {
        parentId: "",
        menuName: "",
        orderNum: 0,
        path: "",
        component: "",
        menuType: "",
        perms: "",
        icon: "",
      },
      rules: {
        parentId: [
          { required: true, message: "父菜单不能为空", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value[0] === "") {
                callback(new Error("父菜单不能为空"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        menuName: [{ required: true, message: "菜单名称不能为空", trigger: "blur" }],
        path: [{ required: true, message: "路由地址不能为空", trigger: "blur" }],
        component: [{ required: true, message: "组件名称不能为空", trigger: "blur" }],
        menuType: [{ required: true, message: "菜单类型不能为空", trigger: "blur" }],
        perms: [{ required: true, message: "权限标识不能为空", trigger: "blur" }],
        icon: [{ required: true, message: "菜单图标不能为空", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    dialogClose() {
      this.$refs["form"].resetFields();
    },
    treeSelect() {
      let url = `${this.baseUrls.menu}/treeselect`;
      this.topsAjax.get(url, { type: 1 }).then((response) => {
        this.menuOptions = response;
      });
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let id = this.getMenuId(this.menuOptions, this.formData.parentId);
          this.formData.parentId = this.formData.parentId[this.formData.parentId.length - 1];
          if (this.formData.parentId === 10010) {
            this.$message.error("不能选择首页,若需添加目录请联系管理员!");
            return;
          }
          this.formData.menuId = id;
          let url = `${this.baseUrls.menu}/topsMenuAdd`;
          this.topsAjax.post(url, this.formData).then((response) => {
            this.$message.success("新增成功");
            this.addMenuVisible = false;
            this.doSearch();
          });
        }
      });
    },
    /**
     * 获取子节点
     * @param list
     * @param parentId
     * @returns {*}
     */
    getChildren(list, parentId) {
      let d = 0;
      list.forEach((item) => {
        if (item.id === parentId) {
          if (item.children) {
            d = item.children;
          } else {
            d = item.id;
          }
        }
      });
      return d;
    },
    /**
     * 获取菜单id
     * @param list
     * @param parentIds
     * @returns {*}
     */
    getMenuId(list, parentIds) {
      let d = list;
      for (let i = 0; i < parentIds.length; ) {
        d = this.getChildren(d, parentIds[i]);
        i++;
        if (i === parentIds.length) {
          if (d.length === undefined) {
            return Number(String(d) + "1");
          } else {
            let maxId = 0;
            d.forEach((item) => {
              maxId = item.id > maxId ? item.id : maxId;
            });
            return ++maxId;
          }
        }
      }
    },
    addMenu() {
      this.title = "新增菜单";
      this.addMenuVisible = true;
    },
    updateMenu(menu) {
      this.title = "修改菜单";
      this.addMenuVisible = true;
    },
    /**
     * 删除
     * @param menu
     */
    deleteMenu(menu) {
      this.$confirm('是否确认删除菜单："' + menu.menuName + '"？')
        .then(() => {
          let url = `${this.baseUrls.menu}/` + menu.menuId;
          this.topsAjax.delete(url).then((response) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.doSearch();
          });
        })
        .catch(() => {});
    },
    /**
     * 修改
     * @param menu
     */
    editMenu() {
      this.topsAjax.put(`${this.baseUrls.menu}/edit`, this.formData).then((response) => {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.doSearch();
      });
    },
    doSearch() {
      this.$refs.list.load();
    },
    indexMethod(index) {
      let page = this.$refs.list.page;
      return index + 1 + (page.currentPage - 1) * page.pageSize;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  padding: 5px;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: white;

  &__body {
    overflow: auto;
    height: 100%;
  }
}
</style>
