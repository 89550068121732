<template>
  <div class="userAddStore">
    <div class="userAddStore__main">
      <div class="left">
        <el-input v-model="filterText" class="wHP" clearable placeholder="输入关键字进行过滤" />
        <div class="scrollbar flex1 marginTop10">
          <el-tree ref="tree" class="filter-tree" show-checkbox :data="storeList" :props="defaultProps" :node-key="'sid'" default-expand-all :filter-node-method="filterNode" @check="handleCheckChange"> </el-tree>
        </div>
      </div>
      <div class="right bcGrey hHP scrollbar">{{ storeString }}</div>
    </div>
    <div class="userAddStore__footer">
      <el-button @click="selectAll">全选</el-button>
      <el-button @click="clearAll">清空</el-button>
      <div class="marginLeft10">已选择 {{ selectNum }} 家</div>
      <el-button class="marginLeftAuto" type="primary" plain @click="onSubmit">提 交</el-button>
      <el-button @click="onCancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAddStore",
  props: {
    merchantId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      storeString: "",
      selectNum: 0,
      filterText: "",
      defaultProps: {
        children: "children",
        label: "cname",
      },
      storeList: [],
      selectNode: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.selectStoreList();
  },
  methods: {
    onCancel() {
      this.$emit("onCancel");
    },
    onSubmit() {
      this.$emit("addStore", this.selectNode);
    },
    selectStoreList() {
      let url = `${this.baseUrls.store}/queryListForSelect`;
      let params = {
        merchantId: this.merchantId,
      };
      this.topsAjax.get(url, params).then((response) => {
        this.storeList = response;
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.cname && data.cname.indexOf(value) !== -1;
    },
    clearAll() {
      this.$refs.tree.setCheckedKeys([]);
      this.selectNum = 0;
      this.joinCheckedNodes();
    },
    selectAll() {
      const allKeys = this.getAllNodeKeys(this.storeList);
      this.$refs.tree.setCheckedKeys(allKeys);
      this.selectNum = allKeys.length;
      this.joinCheckedNodes();
    },
    getAllNodeKeys(nodes) {
      let keys = [];
      nodes.forEach((node) => {
        keys.push(node.sid);
        if (node.children && node.children.length) {
          keys = keys.concat(this.getAllNodeKeys(node.children));
        }
      });
      return keys;
    },
    handleCheckChange(node, checkedNodes) {
      this.selectNum = checkedNodes.checkedNodes.length;
      this.joinCheckedNodes();
    },
    joinCheckedNodes() {
      let selectedNodes = this.$refs.tree.getCheckedNodes(); // 获取被选中的节点对象数组
      let names = selectedNodes.map((node) => node.cname); // 使用 map 方法提取 cname 属性
      let cnameString = names.join(", ");
      this.storeString = cnameString; // 输出拼接后的字符串
      let simplifiedNodes = selectedNodes.map((node) => {
        return {
          sid: node.sid,
          cname: node.cname,
        };
      });
      this.selectNode = simplifiedNodes;
    },
  },
};
</script>

<style lang="scss">
.userAddStore {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  &__main {
    height: 400px;
    display: flex;
    margin-bottom: 10px;

    .left {
      min-width: 40%;
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }

    .right {
      flex: 1;
      display: block;
      padding: 10px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
  }
}
</style>
